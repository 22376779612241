import Vue from 'vue'
import Router from 'vue-router'

// hack router push callback
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch((err) => err)
}

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes:[
        {
            path: '/',
            name: 'home',
            component: () => import('../views/Home.vue'),
        },
        {
            path: '/essaySubmission',
            name: 'essaySubmission',
            component: () => import('../views/EssaySubmission.vue'),
        },
        {
            path: '/caseSubmission',
            name: 'caseSubmission',
            component: () => import('../views/CaseSubmission.vue'),
        },
        {
          path:'/appdownload',
          name:'appdownload',
          component: () => import('../views/Appdownload.vue'),
        }
    ]
});
